const afib_1 = (function () {
    return [

"Hey {{name}} 😀,\n\nWith missing P waves and with a very irregular rhythm, everything points to atrial fibrillation (often just called afib). You can also see that your heart rate is really high ({{heartrate}} BPM), which may be extremely uncomfortable. I’m sorry that you have to go through something like this. I hope it’s at least manageable :/ Try to take slow, deep breaths. Inhale slowly through your nose, feeling your stomach stretch out. Breathe out slowly through your mouth. Then repeat. Please don’t hesitate to seek immediate medical assistance. Afib attacks are no joke.\n\nWarm regards :)",
"Hi {{name}},\n\nThe pattern I'm seeing suggests atrial fibrillation. This is characterized by an irregular rhythm and the absence of P waves in the ECG.\n\nI've also noticed that your heart rate is quite elevated at {{heartrate}} BPM. I can only imagine how unsettling and uncomfortable this must feel for you. Please know that you're not alone in this experience.\n\nI want to offer some immediate support. If you're able, try this calming breathing technique: Gently inhale for a count of four, hold for four, then exhale for four. Repeat this a few times.\n\nRemember, {{name}}, you're showing great strength by monitoring your health. Please take care of yourself.\n\nWishing you all the best. ✨",
"Hello {{name}} 💙 \n\nI've reviewed your ECG. It shows signs of atrial fibrillation (afib) with a very high heart rate of {{heartrate}} BPM, missing p waves, and irregular rhythm. I'm really sorry you're going through this - it must feel really unsettling.\n\nI know this news might be scary, but I'm here to help you understand what's happening. Afib happens when your heart's upper chambers beat irregularly. At your current rate, you might be feeling some distressing symptoms like shortness of breath that might be making you anxious, dizziness or lightheadedness, and chest discomfort.\n\nRemember, while this is serious, afib is manageable with proper care. The key is getting prompt medical attention to start managing your condition effectively.\n\nBest 👋",
"Hello {{name}} 😀,\n\nI've carefully analyzed your ECG, and I want to discuss the results with you. The pattern shows signs of atrial fibrillation (often called afib), characterized by missing P waves and a very irregular rhythm. Your heart rate is also quite high at {{heartrate}} BPM, which I imagine feels extremely uncomfortable.\n\nI'm so sorry you're going through this - it must be really unsettling and perhaps even scary. Afib is when your heart's upper chambers beat irregularly, which can cause symptoms like shortness of breath, dizziness, lightheadedness, and chest discomfort. At your current rate, these symptoms might be quite pronounced.\n\nPlease, try this calming breathing technique: Gently inhale through your nose for a count of four, feeling your stomach expand. Hold for four, then exhale slowly through your mouth for four. Repeat this a few times.\n\nTake care 🤗",
"Hi {{name}} :),\n\nI hope this message finds you as well as possible under the circumstances. \n\nFirst and foremost, your ECG is showing signs of atrial fibrillation, or afib, with a fast heart rate of {{heartrate}} BPM. This explains any discomfort or unusual sensations you might be experiencing right now.\n\nAfib occurs when the upper chambers of your heart aren't beating in sync with the lower chambers. It's characterized by an irregular heart rhythm, absence of P waves in the ECG, and often, a faster-than-normal heart rate (as in your case).\n\nIt's important to know that afib is a manageable condition for many people. You've taken a crucial first step by monitoring your heart :)\n\nWishing you strength. \n\nTake care, {{name}} 😄",
"Hello {{name}} 😊,\n\nI hope you're holding up okay.\n\nI've just reviewed your ECG, and I want to share what I've found:\n\n• Your heart is showing an irregular rhythm\n\n• There's an absence of P waves in the ECG\n\n• Your heart rate is elevated at {{heartrate}} BPM\n\nThese findings point to a rhythm called atrial fibrillation, or afib for short.\n\nGiven your high heart rate, you're likely feeling quite uncomfortable right now. I'm truly sorry you're going through this, {{name}}.\n\nStay strong, {{name}}.\n\nTake good care, and remember we're here to help. 🤗",
"Hey {{name}} 😊,\n\nI hope you're doing okay. I've just finished looking at your ECG results, and I wanted to let you know what I've found.\n\nYour heart's rhythm is irregular right now, and the P waves we usually see in ECGs are missing. Your heart rate is also really high.\n\nAll of these signs point to something called atrial fibrillation. I'm really sorry you're going through this. I know it must be unsettling and maybe even scary. It's not an easy thing to deal with.\n\nIf you're feeling anxious (which would be totally normal), try this: Breathe in slowly through your nose for a count of four, hold for four, then breathe out through your mouth for four. Doing this a few times might help you feel a bit calmer.\n\n{{name}}, I want you to know that you're doing a great job by monitoring your heart. That's a really important step in taking care of yourself.\n\nYou're not alone in this. Wishing you all the best as you navigate this situation 💙",
"Hi {{name}} 💕,\n\nI've looked at your recording results, and it shows something called atrial fibrillation (afib). \n\nAfib means your heart isn't beating in its normal pattern. The top part of your heart is beating too fast and unevenly. This makes the whole heart beat oddly, which is why you might feel your heart racing or skipping beats.\n\nIt may feel really uncomfortable and even scary. You might feel your heart acting strange, and that can make you anxious. But you're not alone in this. Many people have afib, and while it's serious, doctors can help manage it\n\nYou've got this, and we've got you. Sending you a big, warm virtual hug 🌺",
"Hi {{name}} 💗,\n\nYour heart is beating in a rhythm called atrial fibrillation, or afib for short. We can see this because your heart rhythm looks very uneven on the recording, and we can't see the usual P waves that show up when your heart beats normally.\n\nThis means your heart isn't keeping its usual beat. The top part is beating fast and unevenly. That's why you might feel your heart racing or skipping beats.\n\nI see your heart is beating very fast at {{heartrate}} beats per minute. I know this might feel scary, {{name}}. If you're feeling nervous, try this: Put one hand on your belly and one on your chest. Breathe in slowly through your nose while counting to 4. Hold your breath for 4 counts. Then breathe out through your mouth for 4 counts. Do this a few times.\n\nTake care of yourself, {{name}} 😊",
"Hey {{name}} 👋\n\nI've carefully looked at your ECG results and want to share what I found. Your heart is showing a pattern called atrial fibrillation, often called just AFib.\n\nI can tell it's AFib because of two main things I see in your test: there are no regular P waves (which normally show the top part of your heart working), and the bottom part of your heart is beating in an uneven way.\n\nAlso, I see your heart is beating very fast at {{heartrate}} beats per minute. This rapid heart rate may be causing you to experience symptoms such as shortness of breath, fatigue, or a fluttering sensation in your chest.\n\nI'm truly sorry that you have to deal with this, {{name}}.\n\nTake care! 🌸"
];
})();

export default afib_1;