const sinus_rhythm_1PVC = (function () {
    return [
"Hi {{name}},\n\nI analyzed your recording, and it looks like you were able to capture a single Premature Ventricular Complex (PVC). It usually feels like a fluttering sensation in your chest or a skipped beat in your heart rate. Everything else looks good - your underlying rhythm is normal and all measurements are looking ok. PVCs are really common: most people get a few of them daily. As long as you don’t get them frequently, they’re not usually something you should be too worried about.\n\nBest :)",
"Hello {{name}} 😊,\n\nGood news: your ECG looks ok! There's one Premature Ventricular Complex (PVC) in your recording. Single PVCs are very common, and nearly everyone experiences a few of them every day. As long as you don't get them frequently, I wouldn't be too worried. They often don't give any symptoms (which I hope is the case for you), but when they do, it can feel like a fluttering sensation in your chest.Your rhythm (Sinus Rhythm) is a standard heart rhythm - this is what we want to see :). Your heart rate and all PQRST values are also normal.\n\nBest wishes! ❤️",
"Hello 😊,\n\nGreat news, {{name}}! your ECG looks pretty good - Your heart is beating in a normal, regular pattern (what we call Sinus Rhythm). There's one PVC (Premature Ventricular Complex) in your recording. Single PVCs are very common, and nearly everyone experiences them. Even a few of them every day. As long as you don't get them too often, I wouldn't be too worried. They often don't give any symptoms (which I hope is the case for you), but when they do, it can feel like a fluttering sensation in your chest.\n\nBest wishes! ❤️",
"Hi {{name}} 👋,\n\nExamining your ECG, I did find one Premature Ventricular Complex (often called just PVC). It's really, really common. Most people have a few PVCs every day. They often feel like your heart skipped a beat. Everything else looks good, and all your measurements are within the norm. As long as your PVCs aren't frequent, you shouldn't worry about it too much.Despite the single PVC, your underlying heart rhythm is Sinus Rhythm (which is exactly what we want to see), and all your other measurements including heart rate and PQRST values are completely normal.\n\nWarm regards! 💝",
"Hello {{name}} ✨,\n\nI analyzed your recording, and it looks like you were able to capture one PVC. Many healthy people have a few PVCs every single day and don't even notice them. I hope you are one of those people :) A single PVC can be scary (they definitely look scary on an ECG graph), but usually, it's not something to be concerned with.The good news is that outside of that single PVC, everything else in your recording looks perfect - you have a normal heart rhythm and all other measurements are exactly where they should be.\n\nStay well! 💫",
"Hi {{name}} 🌟,\n\nOverall, your ECG looks pretty nice. All measurements are very typical, and your rhythm is normal and regular. But I see that you caught one single Premature Ventricular Complex (PVC). It's usually not dangerous at all. They're super common, and most people have a few of them every day. Many people don't even feel them, but they can cause discomfort. As long as they aren't too frequent, I wouldn't worry about it too much.\n\nBest regards! 💖",
"Hi {{name}} ✨,\n\nI've checked your ECG recording and your underlying heart rhythm is normal (Sinus Rhythm), and all your PQRST measurements are looking good. I did spot a single Premature Ventricular Complex (PVC). Don't worry - these little heart hiccups are actually super common! While they might feel strange (like a flip-flop in your chest), almost everyone experiences them from time to time. The rest of your ECG looks perfectly normal, with all measurements right where they should be. Single PVCs are usually nothing to lose sleep over :)\n\nBest wishes! 💫",
"Hello {{name}} 👋,\n\nI checked your ECG and found Sinus Rhythm (this is regular, standard heart rhythm) with one Premature Ventricular Complex (PVC). Your heart rate is {{heartrate}} BPM, which is perfectly normal, and all other measurements look great too! PVCs are extra beats that can feel like your heart 'skipped' - they're very common and usually harmless. Most people have them daily without even noticing. Since yours is just a single PVC, it's typically nothing to worry about.\n\nTake care! 💝",
"Hi there {{name}} ✨,\n\nLooking at your ECG, I can see that your underlying rhythm is normal and regular. Your heart rate is {{heartrate}} BPM - nicely within the normal range! I did spot one Premature Ventricular Complex (PVC) in there, which might have felt like a little flutter. These extra beats are quite common visitors, and most people experience them without even knowing. Everything else in your recording looks perfectly normal!\n\nStay well! 💫",
"Dear {{name}} ✨,\n\nLooking at your ECG, I spotted a great-looking regular rhythm (Sinus Rhythm) with a single PVC (those extra beats that can feel like tiny flutter). These are very common, and most people experience them daily, often without even noticing! Everything else looks great - your heart rate is a normal {{heartrate}} BPM, and all measurements are right where they should be.\n\nBest wishes! 💝",
"Hi {{name}} 👋,\n\nYour heart rhythm is nice and regular - this is what we want to see :) I did notice one Premature Ventricular Complex (PVC) in your ECG recording. Think of it as your heart doing a little extra beat - they're super common and usually nothing to worry about at all. Your heart rate is {{heartrate}} BPM and all other measurements are perfectly normal. To sum up, nothing you should worry about.\n\nRegards! 💫",
"Hello {{name}} 🌟,\n\nI'm happy to tell you that your baseline heart rhythm is normal! There is one Premature Ventricular Complex (PVC) in your recording - it's like a little hiccup that hearts sometimes do. While they might feel strange, they're usually nothing to worry about. Your heart rate and all other values are within the normal range, which is great news :)\n\nTake care! ❤️",
"Hi {{name}} 😊,\n\nYour heart shows a normal rhythm - which is great.I noticed one PVC (Premature Ventricular Complex), which is just an extra beat that can make your heart feel like it skipped. Don't worry - they're very common and usually harmless. Your heart rate is {{heartrate}} BPM, which is within normal range, and all measurements are exactly where we want them to be.\n\nWarm regards! 💖",
"Hi {{name}} 🌟,\n\nI've got good news about your ECG! Your heart is showing a nice, regular underlying rhythm, which is exactly what we want to see. I did spot something worth mentioning - a single PVC (think of it as your heart adding an extra beat). These are totally normal and most people have them without even realizing it. Your heart rate is normal at {{heartrate}} BPM, and all your other measurements are looking stellar.\n\nKeep thriving! 😊",
"Hey {{name}} 🌸,\n\nJust checked out your recording and wanted to let you know what I found! The main rhythm of your heart is showing a beautiful, normal pattern - always great to see that. There's one little PVC in there (basically your heart throwing in an early beat). No need to worry though - these are super common, and most hearts do this! Looking at your numbers, all your measurements (heart rate and PQRST values) are right on track.\n\nStay amazing! 🌟"];
})();export default sinus_rhythm_1PVC;