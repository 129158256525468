const sinus_tachycardia_1PVC = (function () {
    return [
"Hi {{name}} 👋\n\nI checked your ECG, and it looks like you were able to capture a single Premature Ventricular Complex (PVC). It can be unpleasant, I know. The good news: as long as you don't get too many of them, they're usually not something concerning. Your rhythm is normal, but your heart rate is racing. This is called sinus tachycardia, and it can be caused by many everyday things like caffeine, working out, or extra stress.\n\nTake care! 💫",
"Hello {{name}} ✨\n\nOverall, your ECG looks ok. Sinus tachycardia means that your rhythm is normal and your heart rate is elevated. But please, don't be too worried about it. It sounds scary, but usually, it's just the effect of working out, stress, or too many cups of coffee. All PQRST measurements show normal values :) I also see that you caught one Premature Ventricular Complex (PVC). As long as you don't get too many of them, they're also not something to worry about. It can be uncomfortable to feel a skipped beat. Hopefully, you're handling it well, {{name}} :)\n\nBest wishes! 🌟",
"Hey {{name}} 🌸\n\nAnalyzing your reading, I did notice one abnormal beat. To be exact, there's a single Premature Ventricular Complex (PVC) in your recording. I know that hearing stuff like 'abnormal beat' can be worrisome, but usually, if PVCs aren't too frequent, you shouldn't be concerned about them. They can cause some discomfort, but hopefully, they weren't too awful for you, {{name}}. Other than that, your heart rate is above 100 BPM. It's higher than what we consider standard, but your rhythm is still considered a normal rhythm. This is exactly what sinus tachycardia means: normal sinus rhythm with elevated heart rate :)\n\nWarm regards! 💖",
"Hi {{name}} 🌟\n\nI carefully inspected your recording, and your ECG looks alright! There was just one ectopic beat: a Premature Ventricular Complex (PVC), to be exact. If you don't get PVCs too often, they're typically not something to worry about. But they can be super unpleasant. Your rhythm, sinus tachycardia, is a normal sinus rhythm with an elevated heart rate (above 100 BPM). Also, all of your measurements are nicely within normal range.\n\nStay well! ❤️",
"Hello {{name}} 😄\n\nIt looks like you captured one Premature Ventricular Complex (PVC). Single abnormal beats, usually, aren't something to be too concerned about. Even many healthy people get a few PVCs every single day. But in most cases, they're triggered by things like diet, coffee, medications, or even working out. Other than that, your heart rhythm is normal. Tachycardia is just a medical term for heart rate above 100 BPM :) One more thing, {{name}}, I know that experiencing PVCs can be tough, but I do hope you're managing them as well as you can ❤️\n\nTake care! 🌸",
"Hey {{name}} 🌺\n\nI've reviewed your ECG recording and noticed a single Premature Ventricular Complex (PVC) - one of those extra beats that can feel a bit unsettling. Your heart rate is coming in at {{heartrate}} BPM, which puts you in sinus tachycardia territory (that means an elevated heart rate above 100). Don't worry though! This often happens with exercise, caffeine, or stress. Your underlying rhythm is perfectly normal, as well as all of your PQRST values. Single PVCs like this are actually pretty common. They can feel uncomfortable, but as long as they're just occasional visitors, they're usually nothing to stress about.\n\nWarm regards :)",
"Hi {{name}} 👋\n\nI just looked at your reading and wanted to let you know what I found :) Your heart is beating at {{heartrate}} beats per minute - a bit faster than usual but still showing a normal rhythm (this is what Sinus means). I spotted one extra beat in there (it's called PVC), which might have felt like a little 'thump' or 'skip' in your chest. Please don't worry - these extra beats are super common and usually happen because of everyday things like having caffeine, being stressed, exercising, or not sleeping enough.\n\nFeel better! 💫",
"Hey {{name}} 😊\n\nYour heart's keeping a nice steady rhythm, just moving along a bit quickly at {{heartrate}} beats per minute. I noticed one extra beat in there (this is what we call a PVC). These surprise beats are super common and often pop up when we've had coffee, just exercised, feel a bit stressed, or missed some sleep.\n\nThe most important thing is that your heart's pattern looks good. No need to worry about that extra beat - it's very common :)\n\nKeep taking care of yourself! ✨",
"Hi {{name}} ✨\n\nI just checked your heart reading and wanted to give you a quick update. Your heart rate is {{heartrate}} beats per minute - a bit fast but your rhythm is still normal. I noticed one extra beat (called a PVC) which is very common and usually not a concern. These extra beats often happen after coffee, exercise, during stress, or when you're tired.\n\nThe main thing is that your heart's pattern looks good, and all of your measurements are within the standard range. Single PVC is not something you should worry about, {{name}}.\n\nTake care! 💫",
"Hey {{name}}! 👋\n\nI just checked your ECG and wanted to let you know what I found. Your heart is beating {{heartrate}} times a minute. This is a bit fast. I also saw one extra beat (PVC), but don't worry about it. This happens to lots of people and is usually nothing to be concerned about. The good news is that your overall pattern looks good - this is what SInus Tachycardia means. Regular normal rhythm, just a bit faster :) Additionally, all your PQRST measurements are within normal ranges.\n\nKeep taking good care of yourself! 💕"
];
})();

export default sinus_tachycardia_1PVC;