const sinus_arrythmia_1PVC = (function () {
    return [
"Hi {{name}} 😊,\n\nYour ECG looks alright :) Your rhythm is normal, but your heart goes up when you inhale and slows down when you exhale. This is likely due to your heart synchronizing with your breathing pattern. Your PQRST measurements and heart rate are looking good, and are within standard ranges, \n\n In addition, I noticed one Premature Ventricular Complex (PVC). A single PVC usually isn’t something to be too worried about. In fact, most people get a few of them every day, and most often, they’re triggered by diet, medications, exercise, or anxiety. I know that they can be awful and cause some discomfort. I do hope it’s not too bad for you.\n\nKeep taking care of yourself! ✨",
"Hello {{name}} 👋,\n\nI reviewed your recording, and overall, your ECG looks pretty good! There’s a visible fluctuation in your heart rate (it goes up when you breathe in and goes down when you breathe out), but please don’t worry too much about it! This is a normal finding :) You also caught one Premature Ventricular Complex (PVC). Looks and sounds scary, but most people get a few PVCs every single day. As long as you don’t get them too often, you shouldn’t be worried about it either. You may feel something that people describe as a skipped beat. They can cause mild discomfort, so I hope that this is something you’re handling ok :) \n\nTake care 💙",
"Hey {{name} 🌺,\n\nI analyzed your ECG, and it looks like you caught a rhythm called Sinus Arrhythmia. Sinus arrhythmia means that your heart is beating irregularly. Usually, this is not a cause for concern at all :) It can happen to even super healthy young people and is considered a very normal finding. All other measurements all well within the norm, but I did notice one Premature Ventricular Complex (PVC). They can cause some discomfort -  I hope they aren’t too awful for you. But usually, when you don’t get them too often, it shouldn't be something to worry about too much. Most people get a few PVCs every day.\n\nWarm regards 😃",
"Hey there {{name}},\n\nYour heart rate increases when you breathe in and decreases when you breathe out. It can sound scary, but it’s considered a normal finding. Other than that, it looks like you caught a single Premature Ventricular Complex (PVC). PVCs can feel like a skipped beat. Most people get a few of them every single day. They can be triggered by diet, medications, exercise, or anxiety. As long as you don’t get them too often, they aren’t something you should worry about too much. \n\nBest",
"Hey {{name}}! 👋\n\nI checked your heart info and here's what I found:\n\nYour heart rate changes with your breathing - it goes up when you breathe in and down when you breathe out. This is called sinus arrhythmia and it's totally normal!\n\nI also saw one extra heartbeat (called a PVC). Don't worry - most people get these now and then. They can happen because of things like coffee, stress, or being tired.\n\nOverall, your heart's rhythm looks great, and all of your PQRST values are exactly where they should be.\n\nKeep taking good care of yourself! 💖",
"Hi {{name}} 😄,\n\nI've reviewed your ECG and noticed one Premature Ventricular Complex (PVC). This is an early heartbeat that most people experience occasionally. It can sometimes feel like your heart skipped a beat, but it's usually nothing to worry about. PVCs can be triggered by things like caffeine, stress, or exercise.\n\nYour ECG shows sinus arrhythmia, which means your heart rate changes with your breathing - speeding up when you inhale and slowing down when you exhale. All measurements, including your heart rate of {{heartRate}} BPM, show normal values.\n\nOverall, your heart rhythm looks good. These findings are common and typically not cause for concern. \n\nKeep taking care of your heart! 🤗",
"Hello {{name}} ✨,\n\nI've carefully reviewed your recording and have some insights to share with you.\n\nYour heart is showing a pattern called sinus arrhythmia - I know that arrhythmia in the name sounds scary, but it's actually not something to worry about at all. It's still a normal finding. This means your heart rate naturally fluctuates with your breathing - slightly faster when you inhale, and slower when you exhale. Your heart rate at {{heartRate}} BPM is within normal range, the same as all of your other measurements.\n\nAdditionally, I noticed a single Premature Ventricular Complex (PVC) in your ECG. PVCs are early heartbeats that most people experience occasionally. They might feel like a brief 'flip-flop' in your chest. As you got just one, it's also nothing you should be concerned with :)\n\nTake care :)",
"Hi {{name}} 🌺,\n\nI've thoroughly reviewed your ECG results and want to share some positive news with you.\n\nFirst and foremost, I want to assure you that all your measurements and heart rates are within normal ranges. This is excellent news.\n\nYour ECG shows a pattern called sinus arrhythmia. While the term might sound concerning, it's actually completely normal. It simply means your heart rate gently rises and falls in sync with your breathing :)\n\nI also noticed a single Premature Ventricular Complex (PVC) in your reading. Please don't worry - these are very common and most people experience them from time to time. They're like an occasional early heartbeat and are usually harmless.\n\nTake care and keep up the great work! 🙂",
"Hello {{name}} 💫,\n\nI hope this message finds you well. I've carefully reviewed your ECG results and I'm happy to share some positive insights with you.\n\nYour ECG shows a pattern called sinus arrhythmia. It simply means your heart rate gently rises and falls in sync with your breathing. It's considered normal rhythm - nothing to worry about, {{name}}.\n\nI also found a single PVC (Premature Ventricular Complex) in your ECG. PVCs are very, very common - think of them as an occasional early heartbeat. Most people experience them from time to time, and they're usually nothing to be concerned about.\n\nAlso, I'm pleased to report that all measurements and your heart rate are within normal ranges :)\n\nWarm regards! 💖",
"Hey {{name}} 🤗,\n\nI hope this message finds you well. I've just completed a thorough review of your recent ECG results and I'm pleased to share my findings with you.\n\nYour ECG shows a pattern called sinus arrhythmia. While the name might sound concerning, it's actually a normal finding. It simply means your heart rate naturally varies slightly with your breathing, while keeping a normal overall pattern. I'm also happy to report that all your measurements, including your heart rate, fall within normal ranges. This is excellent news!\n\nI did find a single Premature Ventricular Complex (PVC) in your reading, but please don't worry about it. These are very common, and most people experience them occasionally.\n\nBest regards! 💖"
];
})();

export default sinus_arrythmia_1PVC;