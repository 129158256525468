import axios from 'axios'
import Toastr from './Toaster';

const endpoints = require('./endpoints')

const DEFAULT_ERROR_NOTIFICATION = "Something went wrong!";

// Main API instance
const API = axios.create({
  baseURL: endpoints.APIURL,
  withCredentials: true,
})

// Bobby API instance
const BobbyAPI = axios.create({
  baseURL: 'https://bobby.featherhealth.ai:8080/',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer S*JQNWzYqYzkFD5*qXG-p@u_KYz%FRh2Kz%yPaC4m4pPndu-5d&2f%^VnyEwsZn+S@x8e9^E',
    'Access-Control-Allow-Origin': '*' 
  }
});

API.interceptors.response.use(
  response => handleSuccessResponse(response),
  error => handleErrorResponse(error)
);

BobbyAPI.interceptors.response.use(
  response => handleSuccessResponse(response),
  error => handleErrorResponse(error)
);

const handleSuccessResponse = response => {
  if (response.status === 201) {
    if (response.data.msg) {
      Toastr.success('Created !');
    }
  }
  return response;
};

const handleErrorResponse = axiosErrorObject => {
  if (axiosErrorObject.response?.status === 401) {
    localStorage.removeItem('authenticated')
    setTimeout(() => (window.location.href = "/"), 2000);
  }

  else if (axiosErrorObject.response?.status === 500) {
    if (!axiosErrorObject.response?.config.url.includes('/record/get/')) {
      Toastr.error(
        axiosErrorObject.response?.data?.error || DEFAULT_ERROR_NOTIFICATION
      );
    }
  }
  else if (axiosErrorObject.response?.status === 400) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "UNVALID PARAMS"
    );
  }
  else if (axiosErrorObject.response?.status === 422) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "Unprocessable Entity"
    );
  }
  else if (axiosErrorObject.response?.status === 404) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "NOT FOUND"
    );
  } else if (axiosErrorObject.response?.status === 406) {
    Toastr.error(
      "Automatic check couldn't review this ECG"
    );
  }
  return Promise.reject(axiosErrorObject);
};

// Bobby API methods
API.bobby = {
  getEctopiBeats: (ecgId) => {
    return BobbyAPI.get('/predictions', {
      params: {
        ecg_id: ecgId,
        backend: 'prod'
      }
    });
  },
  
  getPQRST: (ecgId) => {
    return BobbyAPI.get('/pqrstmeasures', {
      params: {
        ecg_id: ecgId,
        backend: 'prod'
      }
    });
  }
};

export default API;
