import React, { useState, useEffect, useRef, useContext } from 'react'

import { Alert, Box, Grid, TextareaAutosize, TextField, Typography, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';

import clsx from 'clsx'

import MultipleSelect from '../MultipleSelect'
import Popup from '../Popup'
import SanityLabel from '../SanityLabel'

import useResizeObserver from '../../hooks/useResizeObserver'
import { sanityErrors as sanityErrorsEnum } from '../../common/enums'
import { useHistory } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { AuthContext } from '../../contexts/Auth.context';

// Messages
import sinus_rhythm from '../../messages/sinus-rhythm.js';
import sinus_arrythmia from '../../messages/sinus-arrythmia.js';
import sinus_tachycardia_1 from '../../messages/sinus-tachycardia-1.js';
import sinus_bradycarida_2 from '../../messages/sinus-bradycardia-2.js';
import sinus_bradycarida_1 from '../../messages/sinus-bradycardia-1.js'; 
import sinus_rhythm_1PVC from '../../messages/sinus_rhythm_1PVC.js'; 
import sinus_tachycardia_1PVC from '../../messages/sinus_tachycardia_1PVC.js'; 
import sinus_arrythmia_1PVC from '../../messages/sinus_arrythmia_1PVC.js'; 
import afib_1 from '../../messages/afib_1.js'; 
import API from '../../common/api.js'

const SPECIAL_RHYTHM_COMBINATIONS = [
  {
    id: 'sinus_rhythm_1pvc',
    baseRhythm: "Sinus Rhythm",
    additionalRhythms: ["Premature Ventricular Contraction (single)"],
    templateKey: "SR1PVC",
    messageTemplates: sinus_rhythm_1PVC
  },
  {
    id: 'sinus_tachycardia_1PVC',
    baseRhythm: "Sinus Tachycardia",
    additionalRhythms: ["Premature Ventricular Contraction (single)"],
    templateKey: "ST1PVC",
    messageTemplates: sinus_tachycardia_1PVC
  },
  {
    id: 'sinus_arrythmia_1PVC',
    baseRhythm: "Sinus Arrhythmia",
    additionalRhythms: ["Premature Ventricular Contraction (single)"],
    templateKey: "SA1PVC",
    messageTemplates: sinus_arrythmia_1PVC
  },
];

const cheatSheetUrl =
  'https://docs.google.com/spreadsheets/d/1tkLcp_rx5ZUYCGfy1OPDnY_fJrxO-lb4/edit#gid=1322797949'
const warmTemplatesUrl =
  'https://docs.google.com/document/d/1SnLmc_3vMogDzzLYoul-BIPpsyypL6bi/edit'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px -1px 2px 0px #0000000D',
    zIndex: 100,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  bigButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#fff',
    backgroundColor: '#0F56B3',
  },
  buttonDisabled: {
    opacity: 0.7,
    cursor: 'default',
  },
  gridCol1: {
    paddingTop: theme.spacing() * 2,
    paddingBottom: theme.spacing() * 2,
    paddingLeft: theme.spacing() * 2,
  },
  gridCol2: {
    padding: theme.spacing() * 2,
  },
  textField: {
    width: '100%',
    margin: "10px"
  },
  helpText: {
    fontSize: '1rem',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  link: {
    textDecoration: 'none',
  },
  sanityErrorsTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    marginBottom: theme.spacing(),
  },
  alert:{
    "& .MuiAlert-icon": {
      marginRight: "5px"
    }, 
    paddingLeft: "10px", 
    paddingTop:"0px",
    width: "100%", 
    background: "white", 
    fontSize: "14px", 
    color: "rgba(1, 13, 61, 0.61)",
    fontWeight: 500,
    alignItems: "center"
  }
}))

function InterpretationBar(props) {

  const { role } = useContext(AuthContext)
  
  const {
    sanityErrors,
    setSnackbarECGView,
    validateSanityError,
    warmMessageRef,
    analysisText,
    activeRecordLabel,
    isLabelling,
    mode,
    finished,
    selectRhythms,
    PVCCount,
    PACCount,
    notes,
    answer,
    name,
    heartrate,
    isAudit,
    isLoadingBobby,
    onBobbyFetch,
    bobbyData
  } = props

  const classes = useStyles()
  const wrapperRef = useRef(null)
  const buttonRef = useRef(null)

  const [warmMessage, setWarmMessage] = useState('')
  const [warmMessageValueType, setWarmMessageValueType] = useState('value')
  const [placeholderHeight, setPlaceholderHeight] = useState(0)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [barWidth, barHeight] = useResizeObserver(wrapperRef)
  
  const [myValue, setMyValue] = useState(null); // Manage myValue with useState

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const quillTester = localStorage.getItem('tester');
  
  useEffect(() => {
    setPlaceholderHeight(barHeight + 8); // 8 is default theme margin
  }, [barHeight, barWidth]);
  
  useEffect(() => {
    console.log(analysisText)
    if (analysisText !== warmMessage) {
      setWarmMessage(analysisText);
      const isError = !analysisText.length;
      console.log(analysisText)
      validateSanityError(isError, sanityErrorsEnum.NO_WARM_MESSAGE);
    }
  }, [analysisText]);
  
  const isGuest = role === "guest"; // Guest is not allowed to Finish Review
  const isSubmitDisabled = (sanityErrors?.length && mode !== "tutorial") || isGuest;
  
  const history = useHistory();
  const handleSubmit = () => {
    if (finished) {
      history.push('/practice');
    }
    else if (!isSubmitDisabled && !isGuest) {
      props.handleSubmit();
    }
  };
  
  const [bobbyResultsHandler, setBobbyResultsHandler] = useState(null);


  useEffect(() => {
    console.log("bobbyData", bobbyData)
    if (bobbyData?.length > 0) {
      setBobbyResultsHandler(bobbyData);
    }
  }, [bobbyData]);
  


  function handleButtonClick() {
    const namesList = myValue.map(item => item.name);
    
    // Centralized rhythm lookup
    const rhythmLookup = {
      "Sinus Rhythm": {
        default: sinus_rhythm,
        "SR1PVC": sinus_rhythm_1PVC
      },
      "Sinus Arrhythmia": {
        default: sinus_arrythmia,
        "SA1PVC": sinus_arrythmia_1PVC
      },
      "Sinus Tachycardia": {
        default: sinus_tachycardia_1,
        "ST1PVC": sinus_tachycardia_1PVC
      },
      "Sinus Bradycardia": {
        default: heartrate >= 40 ? sinus_bradycarida_1 : sinus_bradycarida_2
      },
      "Atrial Fibrillation": {
        default: heartrate > 101 ? afib_1 : null, // Only show AF templates if HR > 101
      }
    };


    
    let rhythmList = [];
  
    // Function to find special rhythm combination
    const findSpecialRhythmCombination = () => {
      for (let combination of SPECIAL_RHYTHM_COMBINATIONS) {
        const hasBaseRhythm = namesList.includes(combination.baseRhythm);
        const hasAllAdditionalRhythms = combination.additionalRhythms.every(
          additionalRhythm => namesList.includes(additionalRhythm)
        );
  
        if (hasBaseRhythm && hasAllAdditionalRhythms) {
          return {
            templates: rhythmLookup[combination.baseRhythm][combination.templateKey],
            combinationId: combination.id
          };
        }
      }
      return null;
    };
  
    // Try to find special rhythm combination first
    const specialCombination = findSpecialRhythmCombination();
    
    if (specialCombination) {
      rhythmList = specialCombination.templates;
    } else {
      // Fallback to default templates
      for (let name of namesList) {
        if (rhythmLookup[name]) {
          rhythmList = rhythmLookup[name].default;
          break;
        }
      }
    }
    
    // Fallback if no templates found
    if (rhythmList.length === 0) {
      console.warn('No message templates found for selected rhythms');
      return;
    }
    
    const randomIndex = Math.floor(Math.random() * rhythmList.length);
    const selectedMessage = rhythmList[randomIndex];
    const nameOnly = name.split(' (')[0];
    const personalizedMessage = selectedMessage
      .replace("{{name}}", nameOnly)
      .replace("{{name}}", nameOnly)
      .replace("{{heartrate}}", heartrate);  
  
    setWarmMessage(personalizedMessage);
    warmMessageRef.current = personalizedMessage;
    const isError = !personalizedMessage.length;
    validateSanityError(isError, sanityErrorsEnum.NO_WARM_MESSAGE);
  }

  
  function handleRhythmsChange(newValue) {
    setMyValue(newValue);
  
    if (!newValue || !Array.isArray(newValue) || newValue.length === 0) {
      setIsButtonDisabled(true);
      return;
    }
  
    // Centralized rhythm lookup
    const rhythmLookup = {
      "Sinus Rhythm": {
        default: sinus_rhythm,
        "SR1PVC": sinus_rhythm_1PVC
      },
      "Sinus Arrhythmia": {
        default: sinus_arrythmia,
        "SA1PVC": sinus_arrythmia_1PVC
      },
      "Sinus Tachycardia": {
        default: sinus_tachycardia_1,
        "ST1PVC": sinus_tachycardia_1PVC
      },
      "Sinus Bradycardia": {
        default: heartrate >= 40 ? sinus_bradycarida_1 : sinus_bradycarida_2
      },
      "Atrial Fibrillation": {
        default: heartrate > 101 ? afib_1 : null, // Only show AF templates if HR > 101
      }
    };
  
    const namesList = newValue.map(item => item.name);
    const isValidNoteLength = notes.length <= 17;
  
    // Check for special rhythm combinations
    const hasSpecialCombination = SPECIAL_RHYTHM_COMBINATIONS.some(combination => {
      const hasBaseRhythm = namesList.includes(combination.baseRhythm);
      const hasAllAdditionalRhythms = combination.additionalRhythms.every(
        additionalRhythm => namesList.includes(additionalRhythm)
      );
      return hasBaseRhythm && hasAllAdditionalRhythms;
    });
  
    // Check if rhythms have templates, including special combinations
    const allRhythmsHaveTemplates = namesList.every(name => {
      //remove after adding second part of AFIB
      if (name === "Atrial Fibrillation" && heartrate <= 101) {
        return false; // Don't allow AF selection if HR <= 101
      }

      return Object.keys(rhythmLookup).includes(name) || 
        SPECIAL_RHYTHM_COMBINATIONS.some(combination => 
          combination.baseRhythm === name || 
          combination.additionalRhythms.includes(name)
        );
    });
  
    // Enable button if:
    // 1. Note length is valid
    // 2. Matches single rhythm or special combination
    const isButtonEnabled = allRhythmsHaveTemplates && 
      isValidNoteLength && 
      (hasSpecialCombination || newValue.length === 1);
  
    setIsButtonDisabled(!isButtonEnabled);
  
    console.log("newValue", newValue)
    props.onRhythmsChange(newValue);
  }  
  
  

  function handleChange(e) {
    const { value } = e.target
    const isError = !value.length
    validateSanityError(isError, sanityErrorsEnum.NO_WARM_MESSAGE)
    setWarmMessage(value)
    warmMessageRef.current = value
  }

  const renderText = () => {
    if (finished) {
      return 'Start next Task'
    }
    if (mode === 'practice') {
      return 'Show Answers'
    }
    else if (mode === 'audit') {
      return 'Close Review'
    }
    else if (mode === 'tutorial') {
      return 'Finish Tutorial'

    }
    return 'Finish Review'
  }

  return (
    <Box style={{ marginTop: placeholderHeight }}>
      <Box className={classes.wrapper} ref={wrapperRef}>
        <Grid container className={classes.fullHeight} wrap="nowrap">
          <Grid
            item
            xs={isLabelling ? 14 : 6}
            className={clsx(isLabelling ? classes.gridCol2 : classes.gridCol1, classes.fullHeight)}
            container
            alignItems="center"
          >
            <MultipleSelect
              ekgid={props.id}
              selectedRecordLabels={props.activeRecordLabel}
              onChange={handleRhythmsChange}
              setSnackbarECGView={setSnackbarECGView}
              onBobbyResults={setBobbyResultsHandler}
            />
            <Alert className={classes.alert}  icon={<InfoOutlinedIcon sx={{ color: "grey " }} />}  >Need help?{' '}
              <a href={cheatSheetUrl} target="blank" className={classes.link}>
                Open cheat sheet
              </a>{' '}
              or{' '}
              <a
                href={warmTemplatesUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Open warm templates
              </a>
            </Alert>

          </Grid>
          {!isLabelling && <Grid

            item
            xs={7}
            className={classes.gridCol2}
            container
            alignItems="center"
          >
          <TextareaAutosize
            id="task-message"
            aria-label="Warm message*"
            placeholder="Warm message*"
            defaultValue={warmMessage}
            variant="outlined"
            value={warmMessage}  // <-- Change this line
            style={{  
              minHeight: 95, 
              padding: 10,        // <-- Top padding
              marginBottom: 0,
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, 
              fontSize: "16px",
            }}
            className={clsx(classes.textField, classes.fullHeight)}
            onFocus={() => setWarmMessageValueType('defaultValue')}
            onBlur={() => setWarmMessageValueType('value')}
            onChange={handleChange}
          />


            <Button
              variant="contained"
              disabled={isButtonDisabled}
              style={{  
                marginBottom: 0,
                marginLeft: 10,  
              }}
              onClick={handleButtonClick}>
              Generate warm message
            </Button>

            {quillTester == "true" && (
              <Button
                variant="contained"
                style={{
                  marginBottom: 0,
                  marginLeft: 10,
                }}
                disabled={isLoadingBobby}
                onClick={onBobbyFetch}
              >
                {isLoadingBobby ? (
                  <>
                    <CircularProgress 
                      size={20} 
                      color="inherit" 
                      style={{ marginRight: 8 }} 
                    />
                    Running...
                  </>
                ) : (
                  'Run automatic check'
                )}
              </Button>
            )}

          
            {/* <TextField
                label="Warm message*"
                id="task-message"
                placeholder="Warm message*"
                variant="outlined"
                className={clsx(classes.textField, classes.fullHeight)}
                maxRows={10}
                onChange={handleChange}
                multiline="true"
                onFocus={() => setWarmMessageValueType('defaultValue')}
                onBlur={() => setWarmMessageValueType('value')}
                {...{ [warmMessageValueType]: warmMessage }}
              /> */}
          </Grid>
          }
          <Grid item container style={{ flexBasis: 200 }} id="task-submit">
            <Grid
              item
              className={clsx(
                classes.fullWidth,
                classes.bigButton,
                isSubmitDisabled && classes.buttonDisabled
              )}
              onClick={handleSubmit}
              ref={buttonRef}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
              style={finished ? { background: '#4CAF50' } : {}}
            >
              <Grid container alignItems="center" justifyContent="center" >
                <CheckIcon />
                {renderText()}
              </Grid>
            </Grid>
          {mode !== " tutorial" &&  <Popup
              open={sanityErrors?.length && isMouseOver}
              anchorEl={buttonRef.current}
              sx={{margin: "20px"}}
            >
              <Typography className={classes.sanityErrorsTitle}>
                To submit review you have to:
              </Typography>
              <SanityLabel
                isError={sanityErrors.includes(
                  sanityErrorsEnum.NO_RHYTHM_SELECTED
                )}
              >
                Select rhythm
              </SanityLabel>
              {!isLabelling && <SanityLabel
                isError={sanityErrors.includes(
                  sanityErrorsEnum.NO_WARM_MESSAGE
                )}
              >
                Add warm message
              </SanityLabel>}
              <SanityLabel
                isError={sanityErrors.includes(sanityErrorsEnum.EMPTY_PQRST)}
              >
                Fill at least one PQRST input
              </SanityLabel>
              <SanityLabel
                isError={sanityErrors.includes(sanityErrorsEnum.NO_FULL_SCROLL)}
              >
                Scroll to last chart
              </SanityLabel>
              
              {isGuest &&
                <SanityLabel
                isError={sanityErrors.includes(sanityErrorsEnum.NO_ALLOW_FINISH_REVIEW)}
                >
                  Guest is not allowed to Finish Review
                </SanityLabel>
              }
              
            </Popup>} 
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default InterpretationBar
