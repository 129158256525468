import React, { useEffect, createContext, useState } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
import API from '../common/api'
import * as webServiceWorker from '../webServiceWorker'
import { useCookies } from 'react-cookie'
import { LAST_LOGIN_VERIFIED_COOKIE, SESSION_COOKIE } from '../common/constants'
import { useHistory } from 'react-router-dom'
import { agreePiiaService } from '../services/auth'


export const AuthContext = createContext(null)

const endpoints = require('../common/endpoints')

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthentificated] = useLocalStorage('authenticated', false)
  const [twoFaEnabled, setTwoFaEnabled] = useLocalStorage('twofaenabled', false)
  const [loginVerified, setLoginVerified] = useLocalStorage('loginverified', false)
  const [role, setRole] = useLocalStorage('quill_role')
  const [username, setUsername] = useLocalStorage('username')
  const [useremail, setUseremail] = useState('useremail')
  const [quillTester, setQuillTester] = useLocalStorage('tester', false)
  const QUILL_TESTER_EMAILS = [
    'xxtest@featherhealth.ai',
    'ewa@qaly.co',
    'pjmadiz15@gmail.com',
    'rhejnoshca@gmail.com',
    'Rhejnoshca@gmail.com'
  ];
  const [piiaAgreement, setPiiaAgreement] = useLocalStorage('piia', false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies()
  const history = useHistory()
  const login = ({ user, password }) => {
    setLoading(true)

    API.post(endpoints.LOGIN, { username: user, password })
      .then(
        (e) => {
          initWebPush()
          
          if (e.data.data.two_fa_enabled === true) {
            setTwoFaEnabled(true)
            // Check expired verified cookies
            if (cookies[LAST_LOGIN_VERIFIED_COOKIE] != undefined) {
              setLoginVerified(true)
            } else {
              setLoginVerified(false)
            }            
          } else {
            setTwoFaEnabled(false)
            setLoginVerified(true)
          }

          setAuthentificated(true)
          setRole(e.data.data.role)
          setUsername(e.data.data.username)
          setPiiaAgreement(e.data.data.piia_agreed)
          setUseremail(user)
          if (QUILL_TESTER_EMAILS.includes(user)) {
            setQuillTester(true);
          } else {
            setQuillTester(false);
          }
                    
          setCookie(SESSION_COOKIE, e.data.data.token, {
            domain: endpoints.Host,
            path: '/',
            sameSite: 'strict',
            expires: new Date(Date.now() + (8 * 60 * 60 * 1000)),
          })
        },
        (e) => {
          setError(e.response.data.msg === ''? false : e.response.data.msg)
        }
      ).finally(() => setLoading(false))
  }

  const logout = () => {
    setLoading(true)

    API.get(endpoints.LOGOUT)
      .then(
        (e) => {
          removeCookie(SESSION_COOKIE)
          console.log('logout')
          localStorage.removeItem('authenticated')
          localStorage.removeItem('loginverified')
          localStorage.removeItem('twofaenabled')
          localStorage.removeItem('username')
          setAuthentificated(false)
          setPiiaAgreement(false)
          setLoginVerified(false)

          history.push('/')
        },
        (e) => {
          localStorage.removeItem('authenticated')
          localStorage.removeItem('loginverified')
          
          console.log(e.response.data.msg)
        }
      )
      .finally(() => setLoading(false))
  }

  const verifyOtp = ({ otp }) => {
    setLoading(true)

    API.post(endpoints.OTPVERIFY, { otp })
      .then(
        (e) => {
          
          setLoginVerified(true)
          setError(null)
          setCookie(LAST_LOGIN_VERIFIED_COOKIE, true, {
            domain: endpoints.Host,
            path: '/',
            sameSite: 'strict',
            expires: new Date(Date.now() + (7 * 24 * 60 * 60 * 1000)), // Expire in 7 days  // Date.now() + (7 * 24 * 60 * 60 * 1000)
          })
        },
        (e) => {
          setLoginVerified(false)
          setError(e.response.data.msg === ''? false : e.response.data.msg)
        }
      ).finally(() => setLoading(false))
  }

  const resendOtp = () => {
    setLoading(true)

    API.post(endpoints.OTPRESEND)
      .then(
        (e) => {
          setError(null)
        },
        (e) => {
          setError(e.response.data.msg === ''? false : e.response.data.msg)
        }
      ).finally(() => setLoading(false))
  }

  const initWebPush = () => {
    webServiceWorker.initializeSubscribeWebPush(registerWebPush)
  }

  const registerWebPush = (subscriptionJSON) => {
    API.post(endpoints.WEBPUSHREGISTER, { subscription: subscriptionJSON})
      .then(
        (e) => {
            console.log("Webpush Register Successfully!")
        },
        (e) => {
          console.log("Webpush Register Error: " + e.response.data.msg === ''? false : e.response.data.msg)
        }
      )
  }

  const piiaOnAgree = async() =>{
    const res = await agreePiiaService()
    console.log(res)
    if(res.code === 200){
      setPiiaAgreement(true)
    }
  }
  return (
    <AuthContext.Provider value={{error, piiaOnAgree, piiaAgreement, authenticated, twoFaEnabled, role, username, useremail, login, logout, verifyOtp, resendOtp, loginVerified, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
